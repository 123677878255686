import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import config from './config';
import { Widget, addResponseMessage, addUserMessage, deleteMessages, toggleWidget, isWidgetOpened } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import logo from './logo.svg';
import './App.css';

let toggle_up = false;
let gUUID = "hello";
let history = { answer: [] };

function App() {
  const getLastMessage = (response, messageType, debug_flag) => {
    for (let i = response.length - 1; i >= 0; i--) {
      if (response[i].type === messageType) {
        if (response[i].data.example === debug_flag) {
          return response[i].data.content;
        }
      }
      if (response[i].type === "human" && response[i].data.example === false) {
        return null;
      }
    }
    return null;
  }


  const fetchChatResponse = () => {
    fetch(`${config[process.env.NODE_ENV].apiBaseUrl}/predict`, {
      method: 'POST',
      body: JSON.stringify(history),
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(
      setTimeout(() => {
        addResponseMessage('Thinking...', 'temp');
      }, 500)
    )
    .then(response => response.json())
    .then(data => {
      console.log("Received response from /predict endpoint:", data);
      history = data;
      
      // wait for 5s

      if(isWidgetOpened() === false){
        if (toggle_up === false){
          setTimeout(() => {
            toggle_up = true;
            toggleWidget();
          }, 5000);
        }
      }

      const lastAIMessage = getLastMessage(data.answer, "ai", false);
      console.log("Last AI message from the response:", lastAIMessage);

      const lastFakeHumanMessage = getLastMessage(data.answer, "human", true);
      
      if (lastFakeHumanMessage) {
        deleteMessages(1);
        addUserMessage(lastFakeHumanMessage);
      }
      if (lastAIMessage) {
        deleteMessages(1,'temp');
        addResponseMessage(lastAIMessage);
      }
    })
    .catch(error => console.error('Error:', error));
  }

  useEffect(() => {
    const chatData = getUUIDAndLastInteracted();
    if (!chatData) {
      // First interaction, set the UUID
      const newUUID = uuidv4();
      updateLastInteracted(newUUID);
      gUUID = newUUID;
      console.log("UUID:", newUUID);
    }
    else {
      gUUID = chatData.uuid;
      console.log("UUID:", chatData.uuid);
      console.log("Last interacted:", chatData.lastInteracted);
    }

    const newAnswer = { 
      data: { 
        content: "Intro",
        additional_kwargs: {},
        example: false,
      },
      type: "human",
      uuid: gUUID,
      empty: "True",
    };
    history.answer.push(newAnswer);
    toggle_up = true;
    toggleWidget();

    fetchChatResponse();
  }, []);

  const handleNewUserMessage = (newMessage) => {
    if (newMessage === '') {
      return;
    }
    else if (newMessage === '.') {
      newMessage = '<empty message>';
      console.log("Empty message, sending debug message instead");
    }

    const newAnswer = { 
      data: { 
        content: newMessage,
        additional_kwargs: {},
        example: false,
      },
      type: "human",
      uuid: gUUID,
      empty: "False",
    };

    history.answer.push(newAnswer);
    console.log("Updated data:", history);

    fetchChatResponse();
  };

  return (
    <div className="App">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        profileAvatar={logo}
        title="Palmetto"
        subtitle="Let's help you have it all with solar"
      />
    </div>
  );
}

function setCookie(name, value, days = 7, path = '/') {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=" + path;
}

function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function updateLastInteracted(uuid) {
  const now = new Date().toISOString();
  setCookie("chatData", JSON.stringify({ uuid, lastInteracted: now }));
}

function getUUIDAndLastInteracted() {
  const cookieData = getCookie("chatData");
  if (cookieData) {
      return JSON.parse(cookieData);
  }
  return null;
}

export default App;
